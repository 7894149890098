import {
  GET_COMPANY_CASES,
  GET_COMPANY_USER_FOR_CASE,
  GET_CASE_DETAILS,
  CREATE_CASE,
  UPDATE_CASE_DETAILS,
  ADD_CASE_USER,
  UPDATE_CASE_USER,
  DELETE_CASE_USER,
  GET_CASE_USER,
  UPDATE_CASE_STATUS,
  UPDATE_CASE_DEFAULT_VALUE,
  GET_COMPANY_USER,
  CREATE_TEST_CASE,
  CREATE_DUPLICATE_CASE,
  DELETE_CASE,
} from '../../shared/constants/ActionTypes';

const initialState = {
  companyCasesData: false,
  companyUserForCaseData: false,
  caseDetailsData: false,
  isCreateCase: false,
  isUpdateCase: false,
  caseUsers: false,
  companyUserDetails: false,
  duplicateCaseDetails: false,
};

const CaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_CASES:
      return {
        ...state,
        companyCasesData: action.payload,
        isCreateCase: false,
        isUpdateCase: false,
      };
    case GET_COMPANY_USER_FOR_CASE:
      return {
        ...state,
        companyUserForCaseData: action.payload,
      };
    case GET_CASE_DETAILS:
      return {
        ...state,
        caseDetailsData: action.payload,
        duplicateCaseDetails: false,
      };
    case CREATE_CASE:
      return {
        ...state,
        isCreateCase: true,
        companyCasesData: false,
      };
    case UPDATE_CASE_DETAILS:
      return {
        ...state,
        isUpdateCase: true,
        companyCasesData: false,
      };
    case UPDATE_CASE_DEFAULT_VALUE:
      return {
        ...state,
        caseDetailsData: false,
      };
    case ADD_CASE_USER:
      return {
        ...state,
        caseUsers: false,
      };
    case UPDATE_CASE_USER:
      return {
        ...state,
        caseUsers: false,
      };
    case DELETE_CASE_USER:
      return {
        ...state,
        caseUsers: false,
      };
    case GET_CASE_USER:
      return {
        ...state,
        caseUsers: action.payload,
      };
    case UPDATE_CASE_STATUS:
      return {
        ...state,
        caseDetailsData: false,
        companyCasesData: false,
      };
    case GET_COMPANY_USER:
      return {
        ...state,
        companyUserDetails: action.payload,
      };
    case CREATE_TEST_CASE:
      return {
        ...state,
        companyCasesData: false,
      };
    case CREATE_DUPLICATE_CASE:
      return {
        ...state,
        duplicateCaseDetails: action.payload,
        companyCasesData: false,
      };
    case DELETE_CASE:
      return {
        ...state,
        companyCasesData: false,
      };
    default:
      return state;
  }
};

export default CaseReducer;
