import React, {useEffect} from 'react';
import {Layout} from 'antd';
import './index.style.less';
// import {FiMoreVertical} from 'react-icons/fi';
import {
  // AiOutlineMenuUnfold,
  // AiOutlineMenuFold,
  // AiFillQuestionCircle,
  MenuFoldOutlined,
  // AiFillRightCircle,
  MenuUnfoldOutlined,
  // AiFillLeftCircle,
} from '@ant-design/icons';
// import {useIntl} from 'react-intl';
// import AppLanguageSwitcher from '../../AppLanguageSwitcher';
// import AppHeaderMessages from '../../AppHeaderMessages';
// import AppNotifications from '../../AppNotifications';
// import {Link} from 'react-router-dom';
// import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {getUserBillingInfo} from 'redux/actions';
import {convertTimestampToDateString} from 'shared/constants/utils';
// import {getBillingInfoByCompanyId} from 'redux/actions/billingInfo';
// import ButtonComponent from 'components/common/buttonComponent';

const AppHeader = ({isCollapsed, onToggleSidebar}) => {
  const {user} = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {Header} = Layout;
  useEffect(() => {
    if (user) {
      dispatch(getUserBillingInfo(user?.id));
    }
  }, [navigate]);

  const {userSubscriptionIsBilling} = useSelector(({common}) => common);

  const renderCancelNotification = () => {
    const {data} = userSubscriptionIsBilling || {};

    if (!data) {
      return null;
    }

    const {
      cancel_at_period_end,
      current_period_end,
      subscription_status,
      is_billing_started,
      is_subscription_active,
      invoice_url,
    } = data;

    if (
      is_billing_started &&
      cancel_at_period_end &&
      subscription_status === 'active'
    ) {
      return (
        <div className='cancel-notification'>
          <h1 style={{color: 'red'}}>
            Your Subscription Will be cancelled at{' '}
            {convertTimestampToDateString(current_period_end)}
          </h1>
        </div>
      );
    }

    if (subscription_status === 'canceled') {
      return (
        <div className='cancel-notification'>
          <h1 style={{background: '#ffe8e8'}}>
            Your Subscription has been expired, please Resubscribe to it.
          </h1>
        </div>
      );
    }

    if (
      (is_billing_started && !is_subscription_active) ||
      subscription_status === 'incomplete'
    ) {
      return (
        <div className='cancel-notification'>
          <h1 style={{background: '#ffe8e8'}}>
            Your free plan validity has been expired, Please subscribe to
            continue.
          </h1>
        </div>
      );
    }

    if (subscription_status === 'past_due') {
      return (
        <div className='cancel-notification'>
          <h1 style={{background: '#ffe8e8'}}>
            Your payment has been failed, please{' '}
            <a
              href={invoice_url}
              rel='noreferrer'
              target='_blank'
              style={{color: 'red', textDecoration: 'underline'}}>
              click here
            </a>{' '}
            to pay.
          </h1>
        </div>
      );
    }

    if (!is_billing_started && subscription_status === 'active') {
      return (
        <div className='cancel-notification'>
          <h1 style={{background: '#ffe8e8'}}>
            Your plan will be changed to free plan from{' '}
            {convertTimestampToDateString(current_period_end)}.
          </h1>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {renderCancelNotification()}
      <Header className='app-header-mini-sidebar'>
        {React.createElement(
          isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'trigger',
            onClick: onToggleSidebar,
          },
        )}

        {/* <Breadcrumb itemRender={itemRender} routes={routes} separator='>' /> */}
        {/* <Search
        className='header-search-mini'
        placeholder={messages['common.searchHere']}
      /> */}
        {/* <ButtonComponent
        btnName={'Upgrade'}
        textcolor={'white'}
        bdr={'none'}
        bgcolor={'#E4891E'}
      />
      <AiFillQuestionCircle
        style={{fontSize: '24px', marginLeft: '10px', color: '#249FE6'}}
      /> */}

        {/* <div className='app-header-mini-sectionDesktop'>
        <AppLanguageSwitcher />
        <AppHeaderMessages />
        <AppNotifications />
      </div> */}
        {/* <div className='app-header-mini-section-mobile'>
        <Dropdown overlay={menuMobile} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div> */}
      </Header>
    </>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
};
