import React from 'react';
import {BiBuildings} from 'react-icons/bi';
import {SettingOutlined} from '@ant-design/icons';
// import { RoutePermittedRole } from 'shared/constants/AppEnums';
import strategyIcon from '../assets/icon/strategyIcon.svg';
import caseIcon from '../assets/icon/caseIcon.svg';
const routesConfig = [
  {
    id: 'home',
    title: '',
    messageId: '',
    type: 'group',
    permittedRole: ['ADMIN', 'case_view'],
    children: [
      {
        testId: 'sidebar-comapany',
        id: 'page-0',
        title: 'Company',
        messageId: 'sidebar.sample.Company',
        type: 'item',
        permittedRole: ['user', 'ADMIN', 'case_view'],
        icon: <BiBuildings className='side-bar-icon' />,
        path: '/home/company',
      },
      {
        testId: 'sidebar-strategy',
        id: 'page-1',
        title: 'Product',
        messageId: 'sidebar.sample.Strategy',
        type: 'item',
        permittedRole: ['user', 'ADMIN'],
        icon: <img src={strategyIcon}></img>,
        path: '/home/strategy',
      },
      {
        testId: 'sidebar-cases',
        id: 'page-2',
        title: 'case',
        messageId: 'sidebar.sample.case',
        type: 'item',
        permittedRole: ['case_view', 'ADMIN'],
        icon: <img src={caseIcon}></img>,
        path: '/home/cases',
      },
      {
        testId: 'sidebar-masters',
        id: 'page-3',
        title: 'masters',
        messageId: 'sidebar.sample.Masters',
        type: 'item',
        permittedRole: ['case_view', 'ADMIN'],
        icon: <SettingOutlined className='side-bar-icon' />,
        path: '/home/masters',
      },
      // {
      //   testId: 'sidebar-payments',
      //   id: 'page-4',
      //   title: 'payments',
      //   messageId: 'sidebar.sample.Payments',
      //   type: 'item',
      //   permittedRole: ['ADMIN'],
      //   icon: <DollarCircleOutlined className='side-bar-icon' />,
      //   path: '/home/packages',
      // },
    ],
  },
];
export default routesConfig;
