import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import Error403 from './errorPages/Error403';
import React from 'react';
import {BusinessCasePagesConfigs} from './businessCase';
import {casePagesConfigs} from './cases';
import {strategyPagesConfigs} from './strategy';
import {CompanyPagesConfigs} from './company';
import {profileConfig} from './profile';
import Error404 from './errorPages/Error404';
// import {PaymentsOfPackages} from './payments';
import {navigationInitialUrl} from 'shared/constants/utils';

const data = navigationInitialUrl();

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...BusinessCasePagesConfigs,
    ...CompanyPagesConfigs,
    ...casePagesConfigs,
    ...strategyPagesConfigs,
    ...profileConfig,
    // ...PaymentsOfPackages,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: data,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
