import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
const TabLayout = React.lazy(() => import('./tabLayout'));

export const BusinessCasePagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/product/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/market/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/customer/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/whatCustomerWants/:businessCaseId',
    element: <TabLayout />,
  },

  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/salesProjection/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/marketingStrategy/:businessCaseId',
    element: <TabLayout />,
  },

  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/salesStrategy/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/engineeringEvaluation/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/activityExecutionStrategy/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/featureExecutionStrategy/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/financial/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/cashflow/:businessCaseId',
    element: <TabLayout />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/caseReport/:businessCaseId',
    element: <TabLayout />,
  },
  // {
  //   permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
  //   path: '/home/reports/:businessCaseId',
  //   element: <TabLayout />,
  // },
  // {
  //   permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
  //   path: '/home/decisionMaking/:businessCaseId',
  //   element: <TabLayout />,
  // },
  // {
  //   permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
  //   path: '/home/alignmentCheckModule/:businessCaseId',
  //   element: <TabLayout />,
  // },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/maintenance/:businessCaseId',
    element: <TabLayout />,
  },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-4',
  //   element: <Page4 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-5',
  //   element: <Page5 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-6',
  //   element: <Page6 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-7',
  //   element: <Page7 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-8',
  //   element: <Page8 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-9',
  //   element: <Page9 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/page-10',
  //   element: <Page10 />,
  // },
];
