import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_STRATEGY,
  UPDATE_STRATEGY,
  CREATE_STRATEGY,
  SHOW_MESSAGE,
  DELETE_STRATEGY,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getStrategyByCompanyId = (CompanyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`strategies/${CompanyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_STRATEGY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addStrategy = (companyId, strategiesData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`strategies/${companyId}`, strategiesData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Strategy successfully created`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_STRATEGY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateStrategyById = (strategiesId, strategiesData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `strategies/${strategiesId}`,
        strategiesData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Strategy successfully updated`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_STRATEGY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Strategy not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteStrategyById = (strategiesId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`strategies/${strategiesId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Strategy successfully deleted`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_STRATEGY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
