import {
  GET_FEATURE,
  CREATE_FEATURE,
  UPDATE_FEATURE,
  DELETE_FEATURE,
  GET_FEATURE_COMPETITOR,
  CUSTOMER_MARKET_COMPETITOR_CHANGED,
} from '../../shared/constants/ActionTypes';

const initialState = {
  featureDetails: false,
  featureCompetitorsDetails: false,
};

const FeatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_MARKET_COMPETITOR_CHANGED:
      return {
        ...state,
        featureDetails: false,
        featureCompetitorsDetails: false,
      };
    case GET_FEATURE_COMPETITOR:
      return {
        ...state,
        featureCompetitorsDetails: action.payload,
      };
    case GET_FEATURE:
      return {
        ...state,
        featureDetails: action.payload,
      };
    case UPDATE_FEATURE:
      return {
        ...state,
        featureDetails: false,
      };
    case CREATE_FEATURE:
      return {
        ...state,
        featureDetails: false,
      };
    case DELETE_FEATURE:
      return {
        ...state,
        featureDetails: false,
      };
    default:
      return state;
  }
};

export default FeatureReducer;
