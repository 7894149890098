import {GET_COUNTRIES_FOR_USER_FORM} from 'shared/constants/ActionTypes';

const initialState = {
  countriesForUserForm: undefined,
};

const formCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_FOR_USER_FORM:
      return {
        ...state,
        countriesForUserForm: action.payload,
      };
    default:
      return state;
  }
};
export default formCountriesReducer;
