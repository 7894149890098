import React from 'react';
import collapseMotion from 'antd/lib/_util/motion';
import {Avatar} from 'antd';
import './index.style.less';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import AppVerticalMenu from '../components/AppVerticalNav';
import {useSidebarContext} from '../../../utility/AppContextProvider/SidebarContextProvider';
import MainSidebar from '../components/MainSidebar';
// import {LogoutOutlined} from '@ant-design/icons';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import logouticon from '../../../../assets/icon/logoutIcon.svg';

const AppSidebar = () => {
  const {isSidebarBgImage} = useSidebarContext();
  const {logout} = useAuthMethod();
  return (
    <MainSidebar
      breakpoint='lg'
      className={clsx('mini-sidebar', {
        'mini-sidebar-img-background': isSidebarBgImage,
      })}
      collapsed={collapseMotion}>
      <img src='/logo192.png' className='truemoso-logo'></img>
      <AppScrollbar className='app-sidebar-mini-scrollbar' scrollToTop={false}>
        <AppVerticalMenu />
        <Avatar className='truemoso-avt-logo' src='/userAvtar.png' />
        <img src={logouticon} className='logout-icon-style' onClick={logout} />
      </AppScrollbar>
    </MainSidebar>
  );
};

export default AppSidebar;
