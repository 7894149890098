import {GET_STATES_FOR_USER_FORM} from 'shared/constants/ActionTypes';

const initialState = {
  statesForUserForm: undefined,
};

const formStatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATES_FOR_USER_FORM:
      return {
        ...state,
        statesForUserForm: action.payload,
      };
    default:
      return state;
  }
};
export default formStatesReducer;
