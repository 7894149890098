import React from 'react';
import {useNavigate} from 'react-router-dom';
import IntlMessages from '../../../@crema/utility/IntlMessages';
import AppAnimateGroup from '../../../@crema/core/AppAnimateGroup';
import {Button} from 'antd';
import '../index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';

const InviteLinkExpire = () => {
  const navigate = useNavigate();

  const onGoBackToHome = () => {
    navigate('../');
  };

  return (
    <AppAnimateGroup type='bottom'>
      <AppPageMetadata title='Unauthorized' />
      <div className='error-container' key='a'>
        <div className='error-content link-error-content'>
          {/* <h3>
            <IntlMessages id='error.410Error' />.
          </h3> */}
          <div className='error-para'>
            <p className='mb-0'>
              <IntlMessages id='error.410Message1' />
            </p>
            {/* <p className='mb-0'>
              <IntlMessages id='error.410Message2' />
            </p> */}
          </div>
          <Button type='primary' className='error-btn' onClick={onGoBackToHome}>
            <IntlMessages id='error.goBackToHome' />
          </Button>
        </div>
      </div>
    </AppAnimateGroup>
  );
};

export default InviteLinkExpire;
