import {REMOVE_COMPANY_USER} from 'shared/constants/ActionTypes';

const initialState = {
  removeCompanyUserOfCompany: undefined,
};

const removeCompanyUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_COMPANY_USER:
      return {
        ...state,
        removeCompanyUserOfCompany: action.payload,
      };
    default:
      return state;
  }
};
export default removeCompanyUserReducer;
