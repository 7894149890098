import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_KNOW_YOUR_MARKET,
  CREATE_KNOW_YOUR_MARKET,
  UPDATE_KNOW_YOUR_MARKET,
  DELETE_KNOW_YOUR_MARKET,
  SOMETHING_WENT_WRONG,
  GET_RISK,
  UPDATE_RISK,
  CREATE_RISK,
  DELETE_RISK,
  GET_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_CUSTOMER_MARKET,
  UPDATE_CUSTOMER_MARKET,
  SHOW_MESSAGE,
  UPDATE_SALES_PROJECTION,
  CUSTOMER_MARKET_COMPETITOR_CHANGED,
  UPDATE_MAINTENANCE,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
  // UPDATE_CASE_DETAILS,
  // UPDATE_CASE_DEFAULT_VALUE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getKnowYourMarketByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`markets/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_KNOW_YOUR_MARKET, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addKnowYourMarket = (knowYourMarketData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`markets`, knowYourMarketData);
      if (data.status === 200) {
        const successMessage = 'Your market sccessfully created';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: CREATE_KNOW_YOUR_MARKET, payload: data.data});
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_CUSTOMER_MARKET});
        dispatch({type: CUSTOMER_MARKET_COMPETITOR_CHANGED});
        dispatch({type: UPDATE_SALES_PROJECTION});
        // dispatch({type: UPDATE_MAINTENANCE});
        // dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        // dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateKnowYourMarketById = (
  knowYourMarketId,
  knowYourMarketData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `markets/${knowYourMarketId}`,
        knowYourMarketData,
      );
      if (data.status === 200) {
        const successMessage = 'Your market sccessfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: UPDATE_KNOW_YOUR_MARKET, payload: data.data});
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_CUSTOMER_MARKET});
        dispatch({type: CUSTOMER_MARKET_COMPETITOR_CHANGED});
        dispatch({type: UPDATE_SALES_PROJECTION});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not Found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteKnowYourMarketById = (knowYourMarketId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`markets/${knowYourMarketId}`);
      if (data.status === 200) {
        const successMessage = 'Your market sccessfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: DELETE_KNOW_YOUR_MARKET, payload: data.data});
        dispatch({type: UPDATE_CUSTOMER_MARKET});
        dispatch({type: CUSTOMER_MARKET_COMPETITOR_CHANGED});
        //  dispatch({type: UPDATE_CASE_DETAILS});
        //  dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_SALES_PROJECTION});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
//Risk&Opportunity

export const getRiskAndOpportunityByTypeAndMarketId = (type, marketId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`markets/${type}/${marketId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        switch (type) {
          case 'risk':
            dispatch({type: GET_RISK, payload: data.data});
            break;
          default:
            dispatch({type: GET_OPPORTUNITY, payload: data.data});
            break;
        }
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addRiskAndOpportunityByType = (type, riskAndOpportunityData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`markets/${type}`, riskAndOpportunityData);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        switch (type) {
          case 'risk':
            dispatch({type: CREATE_RISK, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market risk sccessfully created',
            });
            break;
          default:
            dispatch({type: CREATE_OPPORTUNITY, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market opportunity sccessfully created',
            });
            break;
        }
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Master not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateRiskAndOpportunityById = (
  type,
  id,
  riskAndOpportunityData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `markets/${type}/${id}`,
        riskAndOpportunityData,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        switch (type) {
          case 'risk':
            dispatch({type: UPDATE_RISK, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market risk sccessfully update',
            });
            break;
          default:
            dispatch({type: UPDATE_OPPORTUNITY, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market opportunity sccessfully update',
            });
            break;
        }
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteRiskAndOpportunityById = (type, id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`markets/${type}/${id}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        switch (type) {
          case 'risk':
            dispatch({type: DELETE_RISK, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market risk sccessfully delete',
            });
            break;
          default:
            dispatch({type: DELETE_OPPORTUNITY, payload: data.data});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Your market opportunity sccessfully delete',
            });
            break;
        }
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//Customer_Market
export const getCustomerMarket = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`customerMarket/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_CUSTOMER_MARKET, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCustomerMarketById = (
  customerMarketId,
  customerMarketData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `customerMarket/customerMarket/${customerMarketId}`,
        customerMarketData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Your customer market sccessfully update',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CUSTOMER_MARKET, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Customer Market not Found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
