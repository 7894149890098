import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  USER_SUBSCRIPTION_IS_BILLING,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from '../../shared/constants/ActionTypes';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

export const getUserBillingInfo = (userId) => {
  if (userId) {
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      try {
        let data = await jwtAxios.get(`users/${userId}`);
        if (data.status === 200) {
          //const dataTo = data?.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: USER_SUBSCRIPTION_IS_BILLING,
            payload: data?.data,
          });
        } else {
          // we are doing fetch sucess here so that the loader can go
          dispatch({type: FETCH_SUCCESS});
          // we dont want to show the msg when this API throw the Error
          // dispatch({
          //   type: FETCH_ERROR,
          //   payload: 'Something Went Wrong!',
          // });
        }
      } catch (error) {
        // we are doing fetch sucess here so that the loader can go
        dispatch({type: FETCH_SUCCESS});
        // we dont want to show the msg when this API throw the Error
        //dispatch({type: FETCH_ERROR, payload: 'Something Went Wrong!'});
      }
    };
  }
};
