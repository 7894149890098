import {
  CREATE_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE_CHARACTERISTICS,
  CREATE_CUSTOMER_PROFILE_CHARACTERISTICS,
  UPDATE_CUSTOMER_PROFILE_CHARACTERISTICS,
  DELETE_CUSTOMER_PROFILE_CHARACTERISTICS,
  GET_CUSTOMER_MARKET_COMPETITOR,
  UPDATE_CUSTOMER_MARKET_COMPETITOR,
  CREATE_CUSTOMER_MARKET_COMPETITOR,
  DELETE_CUSTOMER_MARKET_COMPETITOR,
} from '../../shared/constants/ActionTypes';

const initialState = {
  customerProfileDetails: false,
  customerProfileCharacteristicsDetails: false,
  customerMarketCompetitorsDetails: false,
};

const KnowYourMarketReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customerProfileDetails: action.payload,
      };
    case CREATE_CUSTOMER_PROFILE:
      return {
        ...state,
        customerProfileDetails: false,
      };
    case UPDATE_CUSTOMER_PROFILE:
      return {
        ...state,
        customerProfileDetails: false,
      };
    case GET_CUSTOMER_PROFILE_CHARACTERISTICS:
      return {
        ...state,
        customerProfileCharacteristicsDetails: action.payload,
      };
    case CREATE_CUSTOMER_PROFILE_CHARACTERISTICS:
      return {
        ...state,
        customerProfileCharacteristicsDetails: false,
      };
    case UPDATE_CUSTOMER_PROFILE_CHARACTERISTICS:
      return {
        ...state,
        customerProfileCharacteristicsDetails: false,
      };
    case DELETE_CUSTOMER_PROFILE_CHARACTERISTICS:
      return {
        ...state,
        customerProfileCharacteristicsDetails: false,
      };

    case GET_CUSTOMER_MARKET_COMPETITOR:
      return {
        ...state,
        customerMarketCompetitorsDetails: action.payload,
      };
    case CREATE_CUSTOMER_MARKET_COMPETITOR:
      return {
        ...state,
        customerMarketCompetitorsDetails: false,
      };
    case UPDATE_CUSTOMER_MARKET_COMPETITOR:
      return {
        ...state,
        customerMarketCompetitorsDetails: false,
      };
    case DELETE_CUSTOMER_MARKET_COMPETITOR:
      return {
        ...state,
        customerMarketCompetitorsDetails: false,
      };
    default:
      return state;
  }
};

export default KnowYourMarketReducer;
