import {
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_COMPANY_ROLES,
  DELETE_ROLE,
} from '../../shared/constants/ActionTypes';

const initialState = {
  rolesDetails: false,
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_ROLES:
      return {
        ...state,
        rolesDetails: action.payload,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        rolesDetails: false,
      };
    case CREATE_ROLE:
      return {
        ...state,
        rolesDetails: false,
      };
    case DELETE_ROLE:
      return {
        ...state,
        rolesDetails: false,
      };
    default:
      return state;
  }
};

export default rolesReducer;
