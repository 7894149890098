import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CASE_REPORT_DATA,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getCaseReportData = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`cases/caseReport/${caseId}`);

      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_CASE_REPORT_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
      dispatch({type: GET_CASE_REPORT_DATA, payload: false});
    }
  };
};
