import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_COMPANY_ROLES,
  DELETE_ROLE,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getRolesByCompanyId = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`roles/${companyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_ROLES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createRole = (roleData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post('roles', roleData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your role successfully created`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_ROLE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 403:
          errorMessage = 'Insufficient user rights';
          break;
        case 404:
          errorMessage = 'Company not found';
          break;
        case 400:
          errorMessage = 'Role already exist';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateRoleDataById = (roleData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `roles/${roleData.id}/${roleData.company_id}`,
        roleData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your role successfully updated`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_ROLE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Role not found';
          break;
        case 400:
          errorMessage = 'Role already exist';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteRoleById = (roleId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`roles/${roleId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your role successfully deleted`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ROLE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage =
            'The role you are trying to delete is associated with one or more user/invited user';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
