import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PRODUCT_DETAIL_BY_CASE_ID,
  CREATE_BUSINESS_BENEFITS_DATA,
  CREATE_PRODUCT_DATA,
  UPDATE_PRODUCT_DATA,
  GET_BUSINESS_BENEFITS_DATA,
  DELETE_BUSINESS_BENEFITS_DATA,
  UPDATE_BUSINESS_BENEFITS_DATA,
  SOMETHING_WENT_WRONG,
  GET_PRODUCT_IMAGES,
  CREATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  SHOW_MESSAGE,
  GET_PRODUCT_IMAGES_ERROR,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getProductDetailsByCaseId = (caseId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`products/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_PRODUCT_DETAIL_BY_CASE_ID, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: '',
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = ``;
          break;
        case 400:
          errorMessage = ``;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createProductData = (productData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`products`, productData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product sccessfully create`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_PRODUCT_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = `Product not found .`;
          break;
        case 400:
          errorMessage = 'Product already exist';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateProductDataById = (productId, productData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`products/${productId}`, productData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product sccessfully update`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_PRODUCT_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'Product does not exist';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//
export const getBusinessBenefitsData = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`products/business_benefits/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_BUSINESS_BENEFITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = `BusinessBenefitsData not found.`;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const createBusinessBenefitsData = (
  businessBenefitsData,
  company_id,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `products/business_benefits/${company_id}`,
        businessBenefitsData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Business Benefit sccessfully create`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_BUSINESS_BENEFITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Unite not found.';
          break;
        case 400:
          errorMessage = 'Validation error';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const updateBusinessBenefitsData = (productData, productid, key) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `products/business_benefits/${productid}/${key}`,
        productData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Business Benefit sccessfully update`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_BUSINESS_BENEFITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Business Benefits not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteBusinessBenefitsData = (userId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`products/business_benefits/${userId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Business Benefit sccessfully delete`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_BUSINESS_BENEFITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createProductImage = (productId, imageData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `products/product_image/${productId}`,
        imageData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product image sccessfully upload`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_PRODUCT_IMAGE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = `Product not found`;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getProductImages = (productId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`products/product_image/${productId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_PRODUCT_IMAGES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: '',
        });
      }
    } catch (error) {
      dispatch({type: GET_PRODUCT_IMAGES_ERROR});
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = '';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const deleteProductImage = (productId, imageName) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `products/product_image/${productId}/${imageName}`,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product image sccessfully delete`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_PRODUCT_IMAGE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = `Product not found`;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
