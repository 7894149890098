import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_ENGINEERING_EXECUTION_SPECIFICATION,
  UPDATE_ENGINEERING_EXECUTION_SPECIFICATION,
  SHOW_MESSAGE,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
  // UPDATE_CASE_DETAILS,
  // UPDATE_CASE_DEFAULT_VALUE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getEngineeringExecutionSpecificationByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(
        `engineeringExecutionSpecification/${caseId}`,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_ENGINEERING_EXECUTION_SPECIFICATION,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateEngineeringExecutionSpecificationById = (
  engineeringExecutionSpecificationId,
  engineeringExecutionSpecificationData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `engineeringExecutionSpecification/${engineeringExecutionSpecificationId}`,
        engineeringExecutionSpecificationData,
      );
      if (data.status === 200) {
        const successMessage = 'Your Activitie successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: UPDATE_ENGINEERING_EXECUTION_SPECIFICATION,
          payload: data.data,
        });
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Engineering Specification not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
