import React from 'react';
import {Provider} from 'react-redux';

import './shared/styles/crema.less';
import './shared/styles/global.css';
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from './@crema';
import configureStore from './redux/store';
import {BrowserRouter} from 'react-router-dom';
import './@crema/services/index';
import JWTAuthAuthProvider from '@crema/services/auth/jwt-auth/JWTAuthProvider';
import {AxiosInterceptor} from '@crema/services/auth/jwt-auth/jwt-api';

const store = configureStore();
const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <AppThemeProvider>
        <AppLocaleProvider>
          <BrowserRouter>
            <JWTAuthAuthProvider>
              <AxiosInterceptor>
                <AuthRoutes>
                  <AppLayout />
                </AuthRoutes>
              </AxiosInterceptor>
            </JWTAuthAuthProvider>
          </BrowserRouter>
        </AppLocaleProvider>
      </AppThemeProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
