import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
const CreateCompany = React.lazy(() => import('./createCompany'));
const CompanyDetails = React.lazy(() => import('./companyDetails'));
const InviteUser = React.lazy(() => import('./inviteUser'));
const UserList = React.lazy(() => import('./usersList'));
export const CompanyPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.admin,
      RoutePermittedRole.caseViewer,
      RoutePermittedRole.user,
    ],
    path: '/home/create_company',
    element: <CreateCompany />,
  },
  {
    permittedRole: [
      RoutePermittedRole.caseViewer,
      RoutePermittedRole.admin,
      RoutePermittedRole.user,
    ],
    path: '/home/company',
    element: <CompanyDetails />,
  },
  {
    permittedRole: [RoutePermittedRole.admin],
    path: '/home/company/invite_user',
    element: <InviteUser />,
  },
  {
    permittedRole: [RoutePermittedRole.admin],
    path: '/home/company/user_list',
    element: <UserList />,
  },
];
