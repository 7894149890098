import {
  SEND_RESET_PASSWORD_LINK,
  PASSWORD_RESET_SUCCESSFULLY,
  RESET_PASSWORD_TOKEN_EXPIRED,
} from '../../shared/constants/ActionTypes';

const initialState = {
  isInviteTokenExpire: false,
  isPassWordResetSuccessfully: false,
};

const ResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_TOKEN_EXPIRED:
      return {
        ...state,
        isInviteTokenExpire: true,
      };
    case SEND_RESET_PASSWORD_LINK:
      return {
        ...state,
        isInviteTokenExpire: false,
        isPassWordResetSuccessfully: false,
      };
    case PASSWORD_RESET_SUCCESSFULLY:
      return {
        ...state,
        isPassWordResetSuccessfully: true,
      };
    default:
      return state;
  }
};

export default ResetPasswordReducer;
