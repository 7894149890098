import {
  GET_ENGINEERING_EXECUTION_SPECIFICATION,
  UPDATE_ENGINEERING_EXECUTION_SPECIFICATION,
  ENGINEERING_EXECUTION_SPECIFICATION_CHANGED,
} from '../../shared/constants/ActionTypes';

const initialState = {
  engineeringExecutionSpecificationDetails: false,
};

const EngineeringExecutionSpecificationReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ENGINEERING_EXECUTION_SPECIFICATION_CHANGED:
      return {
        ...state,
        engineeringExecutionSpecificationDetails: false,
      };
    case GET_ENGINEERING_EXECUTION_SPECIFICATION:
      return {
        ...state,
        engineeringExecutionSpecificationDetails: action.payload,
      };
    case UPDATE_ENGINEERING_EXECUTION_SPECIFICATION:
      return {
        ...state,
        engineeringExecutionSpecificationDetails: false,
      };
    default:
      return state;
  }
};

export default EngineeringExecutionSpecificationReducer;
