import {
  GET_STRATEGY,
  UPDATE_STRATEGY,
  CREATE_STRATEGY,
  DELETE_STRATEGY,
} from '../../shared/constants/ActionTypes';

const initialState = {
  strategyDetails: false,
};

const StrategyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STRATEGY:
      return {
        ...state,
        strategyDetails: action.payload,
      };
    case UPDATE_STRATEGY:
      return {
        ...state,
        strategyDetails: false,
      };
    case CREATE_STRATEGY:
      return {
        ...state,
        strategyDetails: false,
      };
    case DELETE_STRATEGY:
      return {
        ...state,
        strategyDetails: false,
      };
    default:
      return state;
  }
};

export default StrategyReducer;
