import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
const Strategy = React.lazy(() => import('./strategy'));

export const strategyPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.caseViewer,
      RoutePermittedRole.admin,
      RoutePermittedRole.user,
    ],
    path: '/home/strategy',
    element: <Strategy />,
  },
];
