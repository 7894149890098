import React from 'react'; //  {useState, useEffect}
// import {useNavigate} from 'react-router-dom';
// import collapseMotion from 'antd/lib/_util/motion';
import {Avatar} from 'antd';
import './index.style.less';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';

import AppVerticalMenu from '../components/AppVerticalNav';
import {useSidebarContext} from '../../../utility/AppContextProvider/SidebarContextProvider';
import MainSidebar from '../components/MainSidebar';
// import {LogoutOutlined} from '@ant-design/icons';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import PropTypes from 'prop-types';
import Logo from '../../../../assets/icon/sideBarIcon.png';
import './simple.css';
import {useAuthUser} from '@crema/utility/AuthHooks';
import logouticon from '../../../../assets/icon/logoutIcon.svg';

const AppSidebar = ({isCollapsed}) => {
  const {isSidebarBgImage} = useSidebarContext();
  const {logout} = useAuthMethod();
  const {user} = useAuthUser();
  // const navigate = useNavigate();
  const getUserAvatar = () => {
    if (user?.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  // const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setDeviceWidth(window.innerWidth);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <MainSidebar
      className={clsx('mini-sidebar-toggle', {
        'mini-sidebar-toggle-img-background': isSidebarBgImage,
      })}
      collapsible
      breakpoint='lg'
      collapsedWidth='0'
      collapsed={isCollapsed}>
      {isCollapsed ? (
        <img src='/logo192.png' className='logo-image'></img>
      ) : (
        <div className='logo'>
          <img className='logo-container' src={Logo}></img>
        </div>
      )}
      <AppScrollbar className='app-mini-sidebar-scrollbar'>
        <div className='sideMenu'>
          <AppVerticalMenu />
          {isCollapsed ? (
            <div className='isCollapsedTrue'>
              <Avatar
                // src='/userAvtar.png'
                className='cr-user-info-avatar'>
                {getUserAvatar()}
              </Avatar>

              <div id='logout' className='logout-container' onClick={logout}>
                <img className='logout-icon' src={logouticon} alt='Logout' />
              </div>
            </div>
          ) : (
            <>
              <div className='Avator_logoutBtn'>
                <div className='avator_sub_container'>
                  <Avatar className='cr-user-info-avatar'>
                    {getUserAvatar()}
                  </Avatar>
                  <div className='Actor_name'>
                    <h5>{user.name ? user.name : 'Jhon Doe'}</h5>
                    <div className='flexFont'>
                      <p>
                        {user.email
                          ? user.email.slice(0, 18)
                          : 'Jhondoe@TrueMoso.com'.slice(0, 18)}
                      </p>
                      <p>
                        {user.email
                          ? user.email.slice(18)
                          : 'Jhondoe@TrueMoso.com'.slice(18)}
                      </p>
                    </div>
                  </div>
                </div>
                <button type='submit' className='logOutBtn' onClick={logout}>
                  <img src={logouticon} className='loginButtonHover' />
                  <p>Log out</p>
                </button>
              </div>
            </>
          )}
        </div>
      </AppScrollbar>
    </MainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
};
