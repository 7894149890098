import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import User from './User';
import Company from './company';
import InviteLink from './inviteLink';
import Roles from './roles';
import Case from './case';
import Product from './product';
import ProductCost from './productCost';
import Master from './masters';
import KnowYourMarket from './knowYourMarket';
import CustomerMarket from './customerMarket';
import Feature from './feature';
import SalesProjection from './salesProjection';
import InitiativeStrategies from './initiativeStrategies';
import EngineeringEvolution from './engineeringEvolution';
import Strategy from './strategy';
import EngineeringExecutionActivities from './engineeringExecutionActivities';
import EngineeringExecutionSpecification from './engineeringExecutionSpecification';
import ManPower from './manpower';
import Maintenance from './maintenance';
import FinancialEvaluation from './financialEvaluation';
import ResetPassword from './resetPassword';
import Report from './report';
import StripePackagesReducer from './packageList';
import PaymentIntentReducer from './stripeCard';
import BillingInformationReducer from './billingInfo';
import formCountriesReducer from './userPaymentFormCountry';
import formStatesReducer from './userFormPaymentState';
import removeCompanyUserReducer from './removeCompanyUser';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    user: User,
    company: Company,
    inviteLink: InviteLink,
    roles: Roles,
    cases: Case,
    product: Product,
    productCost: ProductCost,
    master: Master,
    knowYourMarket: KnowYourMarket,
    customerMarket: CustomerMarket,
    feature: Feature,
    salesProjection: SalesProjection,
    initiativeStrategies: InitiativeStrategies,
    engineeringEvolution: EngineeringEvolution,
    strategy: Strategy,
    engineeringExecutionActivities: EngineeringExecutionActivities,
    engineeringExecutionSpecification: EngineeringExecutionSpecification,
    manPower: ManPower,
    maintenance: Maintenance,
    financialEvaluation: FinancialEvaluation,
    resetPassword: ResetPassword,
    report: Report,
    stripePackages: StripePackagesReducer,
    paymentIntent: PaymentIntentReducer,
    billingInformation: BillingInformationReducer,
    countries: formCountriesReducer,
    states: formStatesReducer,
    removeCompanyUserById: removeCompanyUserReducer,
  });

export default reducers;
