import {initialUrl, modulesData, subscriptionUrl} from './AppConst';

/**
 *
 * @param {Array<string>} options
 * @param {Number} Id
 * @returns {string} Name by Id
 */
export const convertIdToName = (options, Id) => {
  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    if (element.id == Id) {
      return element.name;
    }
  }
  return;
};
/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns return difference betweens startDate and endDate in days.
 */
export const countDaysBetweenTwoDates = (startDate, endDate) => {
  let start_date = new Date(startDate);
  let end_date = new Date(endDate);
  let difference_in_time = end_date.getTime() - start_date.getTime();
  let difference_in_days = difference_in_time / (1000 * 60 * 60 * 24);
  return difference_in_days < 0 ? 0 : Math.floor(difference_in_days);
};

/**
 *
 * @param {Array<Object>} masterData
 * @returns Id of masterValue.
 */
export const setValueOfLastAddedMaster = (masterData) => {
  let length = masterData.length;
  let valueOfMaster = length > 0 ? masterData[length - 1].id : null;
  return valueOfMaster;
};
/**
 *
 * @param {Number} duration
 * @param {String} originalDurationType
 * @param {String} newDurationType
 * @returns duration in newType.
 */

export const convertDuration = (
  duration,
  originalDurationType,
  newDurationType,
) => {
  const conversionFactors = {
    SECOND: 1,
    MINUTE: 60,
    HOUR: 3600,
    DAY: 86400,
    WEEK: 604800,
    MONTH: 2629746,
    YEAR: 31556952,
  };

  const originalSeconds = duration * conversionFactors[originalDurationType];
  const newDuration = originalSeconds / conversionFactors[newDurationType];

  return newDuration;
};
/**
 *
 * @param {Number} originalCost
 * @param {String} originalDurationType
 * @param {String} newDurationType
 * @returns cost for newDurationType.
 */
export const convertDurationCost = (
  originalCost,
  originalDurationType,
  newDurationType,
) => {
  let conversionFactor = convertDuration(
    1,
    originalDurationType,
    newDurationType,
  );
  const newCost = originalCost / conversionFactor;
  return newCost;
};

export const setValueOfLastAddedMasterforProductGoal = (masterData) => {
  let length = masterData.length;
  let valueOfMaster = length > 0 ? masterData[length - 1].strategy_id : null;
  return valueOfMaster;
};

export const setCompetitorsList = (data) => {
  const competitors = data?.customers?.map((customer) => {
    let competitorId = 0;
    Object.keys(customer).forEach(function (key) {
      if (typeof customer[key] == 'object') {
        competitorId = Math.max(competitorId, parseInt(key.split('_')[1]));
      }
    });

    return competitorId;
  });
  if (competitors) {
    const maxCompetitor = Math.max(...competitors);
    return maxCompetitor;
  } else {
    return 3;
  }
};

/**
 * The function checks if all the required pages in the pagesVerificationInfo object are present.
 * @param executionType - The executionType parameter is a string that specifies the type of execution.
 * It can have two possible values: "ACTIVITIES" or "SPECIFICATION" value.
 * @param pagesVerificationInfo - An object containing information about the verification status of
 * different pages. The keys of the object represent the page names, and the values represent whether
 * the page has been verified or not.
 * @returns a boolean value. It returns true if all the properties in the `pagesVerificationInfo`
 * object are truthy, and false otherwise.
 */

/**
 * The function "isCaseEditableOrNot" returns true if the case status is "INITIATED" and false
 * otherwise.
 * @param caseStatus - The caseStatus parameter is a number that represents the status of a case. The
 * possible values are:
 * @returns a boolean value. If the caseStatus is equal to 2, it will return true. Otherwise, it will
 * return false.
 */
export const isCaseEditableOrNot = (caseStatus, accessForCaseEdit) => {
  //0 ===>  REJECTED,
  //1 ===>   APPROVED,
  //2 ===>   INITIATED,
  if (caseStatus == 2 && accessForCaseEdit) {
    return true;
  } else {
    return false;
  }
};

export const checkUserHaveAccessToEditCase = (usersAccessRights) => {
  const currentUserId = JSON.parse(localStorage.getItem('userData')).user_id;
  const userCaseAccessRight = usersAccessRights?.find(
    (user) => user?.user_id == currentUserId,
  )?.update_access;

  return userCaseAccessRight;
};

/**
 *  The function "Number format " return Number in formated manner.
 * @returns Value in Number Format
 */
export const numFormat = (value) => {
  const formatted_number = new Intl.NumberFormat('en-IN').format(value);
  return formatted_number;
};

export const stripeNumFormatToUsd = (value) => {
  const price = value / 100;
  const priceInUsd = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return priceInUsd;
};

export const navigationInitialUrl = () => {
  const is_billing_started = localStorage.getItem('is_billing_started');
  if (is_billing_started) {
    return subscriptionUrl;
  } else {
    return initialUrl;
  }
};

export function convertTimestampToDateString(timestamp) {
  // const milliseconds = timestamp * 1000;
  // const dateObject = new Date(milliseconds);
  // const year = dateObject.getFullYear();
  // const month = dateObject.toLocaleString('en-US', {month: 'long'}); // Get full month name
  // const day = dateObject.getDate();
  // const dateString = `${day < 10 ? '0' + day : day}-${
  //   month < 10 ? '0' + month : month
  // }-${year}`;

  const dateString = new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return dateString;
}

/**
 * Checks if the input text contains a word with a length greater than 25 characters
 * and no spaces within that word. Additionally, ensures there is at least one non-space character.
 *
 * @param {string} text - The input text to be checked.
 * @returns {boolean} Returns true if the conditions are met, otherwise false.
 */
export const hasWordWithLength25AndNoSpaces = (text) => {
  if (text.length === 0) {
    return true;
  }
  const containsNonSpace = /\S/.test(text);
  const words = text.split(/\s+/);
  return (
    containsNonSpace &&
    words.every((word) => word.length <= 25 || /\s/.test(word))
  );
};

/**
 * Validates a password based on specified criteria.
 *
 * @param {object} rule - The validation rule.
 * @param {string} value - The password to be validated.
 * @param {function} callback - The callback function.
 * @returns {Promise} A Promise that resolves if the password is valid, rejects with an error message otherwise.
 */
export const validatePassword = (password) => {
  if ((password && password?.length < 8) || password?.length > 16)
    return 'Password length must be between 8 and 16 characters.';
  if (password && !/(?=.*[a-z])/.test(password))
    return 'At least one lowercase letter required.';
  if (password && !/(?=.*[A-Z])/.test(password))
    return 'At least one uppercase letter required.';
  if (password && !/(?=.*\d)/.test(password))
    return 'At least one number required.';
  if (password && !/(?=.*[@#$!%*?&])/.test(password))
    return 'At least one special character required.';
  if (password && /^\s|\s$/.test(password))
    return 'Password cannot start or end with a blank space.';
  if (!password) return 'Please enter password';
  return null; // No errors
};

export const INTERVAL = 3000;

/**
 *  The function "roundUpWithDefault" in nearest value of decimal digits.
 * @returns nearest value of decimal digits
 */
export const roundUpWithDefault = (value, defaultValue = 0) => {
  return Math.ceil(value ?? defaultValue);
};

export function flattenModules(module) {
  return module.subModules?.reduce(
    (acc, subModule) => {
      acc[subModule.moduleName] = subModule.accessRights;
      return {...acc, ...flattenModules(subModule)};
    },
    {[module.moduleName]: module.accessRights},
  );
}

export const flatModulesData = flattenModules(modulesData);

export function hasAccess(flatModules, moduleName, action) {
  return !!flatModules[moduleName]?.[action];
}
export const getAccessRights = () => {
  const userData = localStorage.getItem('userData');
  if (userData) {
    const {metaData} = JSON.parse(userData);
    if (metaData?.length > 0) {
      const {access_rights} = JSON.parse(metaData);
      return access_rights ? JSON.parse(access_rights) : null;
    } else {
      return modulesData;
    }
  }
};
