import {
  GET_MAN_POWER,
  UPDATE_MAN_POWER,
  CREATE_MAN_POWER,
  DELETE_MAN_POWER,
} from '../../shared/constants/ActionTypes';

const initialState = {
  manPowerDetails: false,
};

const ManPowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAN_POWER:
      return {
        ...state,
        manPowerDetails: action.payload,
      };
    case UPDATE_MAN_POWER:
      return {
        ...state,
        manPowerDetails: null,
      };
    case CREATE_MAN_POWER:
      return {
        ...state,
        manPowerDetails: null,
      };
    case DELETE_MAN_POWER:
      return {
        ...state,
        manPowerDetails: null,
      };
    default:
      return state;
  }
};

export default ManPowerReducer;
