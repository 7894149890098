import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_ENGINEERING_EVOLUTIONS,
  UPDATE_ENGINEERING_EVOLUTIONS,
  CREATE_ENGINEERING_EVOLUTIONS,
  DELETE_ENGINEERING_EVOLUTIONS,
  SHOW_MESSAGE,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getEngineeringEvolutionsByFeatureId = (featureId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`engineeringEvolutions/${featureId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ENGINEERING_EVOLUTIONS, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Feature not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addEngineeringEvolutions = (caseId, engineeringEvolutionsData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `engineeringEvolutions/${caseId}`,
        engineeringEvolutionsData,
      );
      if (data.status === 200) {
        const successMessage =
          'Your Engineering Specification successfully add';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: CREATE_ENGINEERING_EVOLUTIONS, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateEngineeringEvolutionsById = (
  engineeringEvolutionsId,
  engineeringEvolutionsData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `engineeringEvolutions/${engineeringEvolutionsId}`,
        engineeringEvolutionsData,
      );
      if (data.status === 200) {
        const successMessage =
          'Your Engineering Specification successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: UPDATE_ENGINEERING_EVOLUTIONS, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Engineering evolution not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteEngineeringEvolutionsById = (engineeringEvolutionsId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `engineeringEvolutions/${engineeringEvolutionsId}`,
      );
      if (data.status === 200) {
        const successMessage =
          'Your Engineering Specification successfully delete';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: DELETE_ENGINEERING_EVOLUTIONS, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
