import {
  GET_KNOW_YOUR_MARKET,
  CREATE_KNOW_YOUR_MARKET,
  UPDATE_KNOW_YOUR_MARKET,
  GET_RISK,
  UPDATE_RISK,
  CREATE_RISK,
  DELETE_RISK,
  GET_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  DELETE_OPPORTUNITY,
  GET_CUSTOMER_MARKET,
  UPDATE_CUSTOMER_MARKET,
  GET_UPDATED_CUSTOMER_COUNT,
  DELETE_KNOW_YOUR_MARKET,
} from '../../shared/constants/ActionTypes';

const initialState = {
  knowYourMarketDetails: false,
  riskDetails: false,
  opportunityDetails: false,
  customerMarketDetails: false,
};

const KnowYourMarketReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPDATED_CUSTOMER_COUNT:
      return {
        ...state,
        customerMarketDetails: false,
      };
    case GET_KNOW_YOUR_MARKET:
      return {
        ...state,
        knowYourMarketDetails: action.payload,
        customerMarketDetails: false,
      };
    case UPDATE_KNOW_YOUR_MARKET:
      return {
        ...state,
        knowYourMarketDetails: false,
      };
    case CREATE_KNOW_YOUR_MARKET:
      return {
        ...state,
        knowYourMarketDetails: false,
      };
    case DELETE_KNOW_YOUR_MARKET:
      return {
        ...state,
        knowYourMarketDetails: false,
      };
    case GET_RISK:
      return {
        ...state,
        riskDetails: action.payload,
      };
    case CREATE_RISK:
      return {
        ...state,
        riskDetails: false,
      };
    case UPDATE_RISK:
      return {
        ...state,
        riskDetails: false,
      };
    case DELETE_RISK:
      return {
        ...state,
        riskDetails: false,
      };
    case GET_OPPORTUNITY:
      return {
        ...state,
        opportunityDetails: action.payload,
      };
    case CREATE_OPPORTUNITY:
      return {
        ...state,
        opportunityDetails: false,
      };
    case UPDATE_OPPORTUNITY:
      return {
        ...state,
        opportunityDetails: false,
      };
    case DELETE_OPPORTUNITY:
      return {
        ...state,
        opportunityDetails: false,
      };
    case GET_CUSTOMER_MARKET:
      return {
        ...state,
        customerMarketDetails: action.payload,
      };
    case UPDATE_CUSTOMER_MARKET:
      return {
        ...state,
        customerMarketDetails: false,
      };
    default:
      return state;
  }
};

export default KnowYourMarketReducer;
