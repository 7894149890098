import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SEND_RESET_PASSWORD_LINK,
  VERIFY_RESET_PASSWORD_TOKEN,
  PASSWORD_RESET_SUCCESSFULLY,
  RESET_PASSWORD_TOKEN_EXPIRED,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const sendResetPasswordMail = (resetPasswordData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `auth/request-reset-password`,
        resetPasswordData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Reset Password Link Sent',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SEND_RESET_PASSWORD_LINK, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Email not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const verifyResetPasswordToken = (token) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`auth/verify-token/${token}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: VERIFY_RESET_PASSWORD_TOKEN, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 410:
          dispatch({
            type: RESET_PASSWORD_TOKEN_EXPIRED,
            payload: 'Token Expired',
          });
          dispatch({
            type: FETCH_ERROR,
            payload: 'Token Expired',
          });
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          dispatch({type: FETCH_ERROR, payload: errorMessage});
          break;
      }
    }
  };
};

export const resetPasswordWithToken = (resetPasswordData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`auth/reset-password`, resetPasswordData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Password Reset Successfully',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: PASSWORD_RESET_SUCCESSFULLY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 410:
          dispatch({
            type: RESET_PASSWORD_TOKEN_EXPIRED,
            payload: 'Token Expired',
          });
          dispatch({
            type: FETCH_ERROR,
            payload: 'Token Expired',
          });
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          dispatch({type: FETCH_ERROR, payload: errorMessage});
          break;
      }
    }
  };
};
