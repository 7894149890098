import {
  GET_PRODUCT_COST,
  CREATE_PRODUCT_COST,
  UPDATE_PRODUCT_COST,
  DELETE_PRODUCT_COST,
} from '../../shared/constants/ActionTypes';

const initialState = {
  productCostDetails: false,
};

const ProductCostReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_COST:
      return {
        ...state,
        productCostDetails: action.payload,
      };
    case UPDATE_PRODUCT_COST:
      return {
        ...state,
        productCostDetails: false,
      };
    case CREATE_PRODUCT_COST:
      return {
        ...state,
        productCostDetails: false,
      };
    case DELETE_PRODUCT_COST:
      return {
        ...state,
        productCostDetails: false,
      };
    default:
      return state;
  }
};

export default ProductCostReducer;
