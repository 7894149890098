import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PRODUCT_COST,
  CREATE_PRODUCT_COST,
  UPDATE_PRODUCT_COST,
  DELETE_PRODUCT_COST,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getProductCostByProductId = (productId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`products/product_cost/${productId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_PRODUCT_COST, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Product not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addProductCostByProductId = (productId, productCostData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `products/product_cost/${productId}`,
        productCostData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product cost sccessfully create`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_PRODUCT_COST, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'Validation error';
          break;
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateProductCostById = (productId, id, productCostData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `products/product_cost/${productId}/${id}`,
        productCostData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product cost sccessfully update`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_PRODUCT_COST, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'Validation Error';
          break;
        case 404:
          errorMessage = 'Not Found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteProductCostById = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`products/product_cost/${id}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your product cost sccessfully delete`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_PRODUCT_COST, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
