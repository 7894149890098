import {
  CANCEL_SUBSCRIPTION_OF_COMPANY_ADMIN,
  GET_BILLING_INFORMATION_FOR_COMPANY,
  GET_USER_LIST_FOR_COMPANY,
} from 'shared/constants/ActionTypes';

const initialState = {
  billingInfoForCompany: undefined,
  userListForCompany: undefined,
  cancelSubscription: undefined,
};

const BillingInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLING_INFORMATION_FOR_COMPANY:
      return {
        ...state,
        billingInfoForCompany: action.payload,
      };

    case GET_USER_LIST_FOR_COMPANY:
      return {
        ...state,
        userListForCompany: action.payload,
      };

    case CANCEL_SUBSCRIPTION_OF_COMPANY_ADMIN:
      return {
        ...state,
        cancelSubscription: action.payload,
      };
    default:
      return state;
  }
};
export default BillingInformationReducer;
