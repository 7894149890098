import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_MAINTENANCE,
  UPDATE_MAINTENANCE,
  CREATE_MAINTENANCE,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  UPDATE_FINANCIAL_EVALUATION,
  // UPDATE_CASE_DEFAULT_VALUE,
  UPDATE_CASH_FLOW,
  UPDATE_CASE_DETAILS,
  UPDATE_CASE_DEFAULT_VALUE,
  // UPDATE_CASE_DETAILS,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getMaintenanceByCaseId = (companyId, caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`maintenance/${companyId}/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_MAINTENANCE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addMaintenanceByCaseId = (companyId, caseId, maintenanceYears) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `maintenance/${companyId}/${caseId}/${maintenanceYears}`,
        {},
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your maintenance successfully create`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: CREATE_MAINTENANCE, payload: data.data});
        dispatch({type: UPDATE_CASE_DETAILS});
        dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateMaintenanceById = (maintenanceData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`maintenance`, maintenanceData);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your maintenance successfully update`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: UPDATE_MAINTENANCE, payload: data.data});
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Maintenance not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
