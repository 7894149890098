import {GET_USER_ROLE_IN_COMPANY} from '../../shared/constants/ActionTypes';

const initialState = {
  userRoleInCompany: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ROLE_IN_COMPANY:
      return {
        ...state,
        userRoleInCompany: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
