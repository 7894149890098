import {
  GET_FINANCIAL_EVALUATION,
  UPDATE_FINANCIAL_EVALUATION,
  CREATE_FINANCIAL_EVALUATION,
  DELETE_FINANCIAL_EVALUATION,
  GET_CASH_FLOW,
  UPDATE_CASH_FLOW,
} from '../../shared/constants/ActionTypes';

const initialState = {
  financialEvaluationDetails: false,
  cashFlowDetails: false,
};

const FinancialEvaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCIAL_EVALUATION:
      return {
        ...state,
        financialEvaluationDetails: action.payload,
        cashFlowDetails: false,
      };
    case UPDATE_FINANCIAL_EVALUATION:
      return {
        ...state,
        financialEvaluationDetails: false,
      };
    case CREATE_FINANCIAL_EVALUATION:
      return {
        ...state,
        financialEvaluationDetails: false,
      };
    case DELETE_FINANCIAL_EVALUATION:
      return {
        ...state,
        financialEvaluationDetails: false,
      };
    case UPDATE_CASH_FLOW:
      return {
        ...state,
        cashFlowDetails: false,
      };
    case GET_CASH_FLOW:
      return {
        ...state,
        cashFlowDetails: action.payload,
      };
    default:
      return state;
  }
};

export default FinancialEvaluationReducer;
