import {
  GET_ENGINEERING_EVOLUTIONS,
  UPDATE_ENGINEERING_EVOLUTIONS,
  CREATE_ENGINEERING_EVOLUTIONS,
  DELETE_ENGINEERING_EVOLUTIONS,
} from '../../shared/constants/ActionTypes';

const initialState = {
  engineeringEvolutionDetails: false,
};

const EngineeringEvolutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENGINEERING_EVOLUTIONS:
      return {
        ...state,
        engineeringEvolutionDetails: action.payload,
      };
    case UPDATE_ENGINEERING_EVOLUTIONS:
      return {
        ...state,
        engineeringEvolutionDetails: false,
      };
    case CREATE_ENGINEERING_EVOLUTIONS:
      return {
        ...state,
        engineeringEvolutionDetails: false,
      };
    case DELETE_ENGINEERING_EVOLUTIONS:
      return {
        ...state,
        engineeringEvolutionDetails: false,
      };
    default:
      return state;
  }
};

export default EngineeringEvolutionReducer;
