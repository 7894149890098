import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_FEATURE,
  CREATE_FEATURE,
  UPDATE_FEATURE,
  DELETE_FEATURE,
  GET_FEATURE_COMPETITOR,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  UPDATE_ENGINEERING_EVOLUTIONS,
  UPDATE_ENGINEERING_EXECUTION_SPECIFICATION,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
  // UPDATE_CASE_DETAILS,
  // UPDATE_CASE_DEFAULT_VALUE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getFeatureByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`feature/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_FEATURE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getFeatureCompetitorByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`feature/competitors/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_FEATURE_COMPETITOR, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addFeatureByCaseId = (caseId, featureData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`feature/${caseId}`, featureData);
      if (data.status === 200) {
        const successMessage = 'Your Feature successfully add';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: CREATE_FEATURE, payload: data.data});
        //  dispatch({type: UPDATE_CASE_DETAILS});
        //  dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_ENGINEERING_EVOLUTIONS});
        dispatch({type: UPDATE_ENGINEERING_EXECUTION_SPECIFICATION});
        // dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        // dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateFeatureById = (featureId, featureData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`feature/${featureId}`, featureData);
      if (data.status === 200) {
        const successMessage = 'Your Feature successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: UPDATE_FEATURE, payload: data.data});
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_ENGINEERING_EVOLUTIONS});
        dispatch({type: UPDATE_ENGINEERING_EXECUTION_SPECIFICATION});
        // dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        // dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
        case 404:
          errorMessage = 'feature not Found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteFeatureById = (featureId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`feature/${featureId}`);
      if (data.status === 200) {
        const successMessage = 'Your Feature successfully delete';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: DELETE_FEATURE, payload: data.data});
        //  dispatch({type: UPDATE_CASE_DETAILS});
        //  dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_ENGINEERING_EVOLUTIONS});
        dispatch({type: UPDATE_ENGINEERING_EXECUTION_SPECIFICATION});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
