import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CUSTOMER_MARKET_COMPETITOR,
  UPDATE_CUSTOMER_MARKET_COMPETITOR,
  CREATE_CUSTOMER_MARKET_COMPETITOR,
  DELETE_CUSTOMER_MARKET_COMPETITOR,
  CREATE_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE_CHARACTERISTICS,
  CREATE_CUSTOMER_PROFILE_CHARACTERISTICS,
  UPDATE_CUSTOMER_PROFILE_CHARACTERISTICS,
  DELETE_CUSTOMER_PROFILE_CHARACTERISTICS,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

//Customer_Market_Competitors

export const getCustomerMarketCompetitorsByCustomerMarketId = (
  customerMarketId,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(
        `customerMarket/customerMarketCompetitors/${customerMarketId}`,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_CUSTOMER_MARKET_COMPETITOR,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addCustomerMarketCompetitorsByCustomerMarketId = (
  customerMarketId,
  customerMarketCompetitorsData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `customerMarket/${customerMarketId}`,
        customerMarketCompetitorsData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Your Customer profile successfully created',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: CREATE_CUSTOMER_MARKET_COMPETITOR,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        case 400:
          errorMessage = 'Customer already exist for this market.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCustomerMarketCompetitorsByCustomerMarketId = (
  customerMarketId,
  customerMarketCompetitorsData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `customerMarket/${customerMarketId}`,
        customerMarketCompetitorsData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Your Customer profile successfully update',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: UPDATE_CUSTOMER_MARKET_COMPETITOR,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        case 400:
          errorMessage = 'Customer already exist for this market.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteCustomerMarketCompetitorsByCustomerMarketId = (
  customerMarketId,
  customerId,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `customerMarket/${customerMarketId}/${customerId}`,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Your Customer profile successfully delete',
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: DELETE_CUSTOMER_MARKET_COMPETITOR,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//Customer_Profile
export const getCustomerProfileByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`customers/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_CUSTOMER_PROFILE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addCustomerProfile = (customerProfileData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`customers`, customerProfileData);
      if (data.status === 200) {
        const successMessage = 'Your Customer Profile successfully created';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_CUSTOMER_PROFILE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCustomerProfileById = (
  customerProfileId,
  customerProfileData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `customers/${customerProfileId}`,
        customerProfileData,
      );
      if (data.status === 200) {
        const successMessage = 'Your Customer Profile successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CUSTOMER_PROFILE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Customer not Found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//Customer_Profile_Characteristics

export const getCustomerProfileCharacteristics = (customerProfileId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(
        `customers/characteristic/${customerProfileId}`,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_CUSTOMER_PROFILE_CHARACTERISTICS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addCustomerProfileCharacteristics = (
  customerProfileId,
  characteristicsData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `customers/characteristic/${customerProfileId}`,
        characteristicsData,
      );
      if (data.status === 200) {
        const successMessage = 'Your Profile Characteristics successfully add';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: CREATE_CUSTOMER_PROFILE_CHARACTERISTICS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Customer not found';
          break;
        case 400:
          errorMessage = 'Characteristics already exist';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCustomerProfileCharacteristicsById = (
  customerProfileId,
  characteristicsId,
  characteristicsData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `customers/characteristic/${customerProfileId}/${characteristicsId}`,
        characteristicsData,
      );
      if (data.status === 200) {
        const successMessage =
          'Your Profile Characteristics successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: UPDATE_CUSTOMER_PROFILE_CHARACTERISTICS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Characteristics already exist';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({
        type: GET_CUSTOMER_PROFILE_CHARACTERISTICS,
        payload: false,
      });
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteCustomerProfileCharacteristicsById = (characteristicsId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `customers/characteristic/${characteristicsId}`,
      );
      if (data.status === 200) {
        const successMessage =
          'Your Profile Characteristics successfully delete';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: DELETE_CUSTOMER_PROFILE_CHARACTERISTICS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
