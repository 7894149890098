import {
  CREATE_MASTER_PRODUCT_UNITE,
  CREATE_MASTER_PRODUCT_FREQUENCY,
  CREATE_MASTER_MARKET_UNITE,
  CREATE_MASTER_CUSTOMER_COMPETITOR,
  CREATE_MASTER_CUSTOMER_CHARACTERISTICS,
  CREATE_MASTER_FEATURE_TYPE,
  CREATE_MASTER_RECURRENCE,
  CREATE_MASTER_ENGINEERING_EVOLUTION_TYPE,
  CREATE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE,
  CREATE_MASTER_DEVELOPMENT,
  UPDATE_MASTER_PRODUCT_UNITE,
  UPDATE_MASTER_PRODUCT_FREQUENCY,
  UPDATE_MASTER_MARKET_UNITE,
  UPDATE_MASTER_CUSTOMER_COMPETITOR,
  UPDATE_MASTER_CUSTOMER_CHARACTERISTICS,
  UPDATE_MASTER_FEATURE_TYPE,
  UPDATE_MASTER_RECURRENCE,
  UPDATE_MASTER_ENGINEERING_EVOLUTION_TYPE,
  UPDATE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE,
  UPDATE_MASTER_DEVELOPMENT,
  GET_MASTER_PRODUCT_UNITE,
  GET_MASTER_PRODUCT_FREQUENCY,
  GET_MASTER_MARKET_UNITE,
  GET_MASTER_CUSTOMER_COMPETITOR,
  GET_MASTER_CUSTOMER_CHARACTERISTICS,
  GET_MASTER_FEATURE_TYPE,
  GET_MASTER_RECURRENCE,
  GET_MASTER_ENGINEERING_EVOLUTION_TYPE,
  GET_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE,
  GET_MASTER_DEVELOPMENT,
  DELETE_MASTER_PRODUCT_UNITE,
  DELETE_MASTER_PRODUCT_FREQUENCY,
  DELETE_MASTER_MARKET_UNITE,
  DELETE_MASTER_CUSTOMER_COMPETITOR,
  DELETE_MASTER_CUSTOMER_CHARACTERISTICS,
  DELETE_MASTER_FEATURE_TYPE,
  DELETE_MASTER_RECURRENCE,
  DELETE_MASTER_ENGINEERING_EVOLUTION_TYPE,
  DELETE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE,
  DELETE_MASTER_DEVELOPMENT,
} from '../../shared/constants/ActionTypes';

const initialState = {
  masterProductUniteDetails: false,
  masterProductFrequencyDetails: false,
  masterMarketUniteDetails: false,
  masterCustomerCompetitorDetails: false,
  masterCustomerCharacteristicsDetails: false,
  masterFeatureTypeDetails: false,
  masterRecurrence: false,
  masterEngineeringEvolutionType: false,
  masterEngineeringEvolutionRiskType: false,
  masterDevelopment: false,
};

const MastersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTER_PRODUCT_UNITE:
      return {
        ...state,
        masterProductUniteDetails: action.payload,
      };
    case CREATE_MASTER_PRODUCT_UNITE:
      return {
        ...state,
        masterProductUniteDetails: false,
      };
    case UPDATE_MASTER_PRODUCT_UNITE:
      return {
        ...state,
        masterProductUniteDetails: false,
      };
    case DELETE_MASTER_PRODUCT_UNITE:
      return {
        ...state,
        masterProductUniteDetails: false,
      };
    case GET_MASTER_PRODUCT_FREQUENCY:
      return {
        ...state,
        masterProductFrequencyDetails: action.payload,
      };
    case CREATE_MASTER_PRODUCT_FREQUENCY:
      return {
        ...state,
        masterProductFrequencyDetails: false,
      };
    case UPDATE_MASTER_PRODUCT_FREQUENCY:
      return {
        ...state,
        masterProductFrequencyDetails: false,
      };
    case DELETE_MASTER_PRODUCT_FREQUENCY:
      return {
        ...state,
        masterProductFrequencyDetails: false,
      };
    case GET_MASTER_MARKET_UNITE:
      return {
        ...state,
        masterMarketUniteDetails: action.payload,
      };
    case CREATE_MASTER_MARKET_UNITE:
      return {
        ...state,
        masterMarketUniteDetails: false,
      };
    case UPDATE_MASTER_MARKET_UNITE:
      return {
        ...state,
        masterMarketUniteDetails: false,
      };
    case DELETE_MASTER_MARKET_UNITE:
      return {
        ...state,
        masterMarketUniteDetails: false,
      };

    case GET_MASTER_CUSTOMER_CHARACTERISTICS:
      return {
        ...state,
        masterCustomerCharacteristicsDetails: action.payload,
      };
    case CREATE_MASTER_CUSTOMER_CHARACTERISTICS:
      return {
        ...state,
        masterCustomerCharacteristicsDetails: false,
      };
    case UPDATE_MASTER_CUSTOMER_CHARACTERISTICS:
      return {
        ...state,
        masterCustomerCharacteristicsDetails: false,
      };
    case DELETE_MASTER_CUSTOMER_CHARACTERISTICS:
      return {
        ...state,
        masterCustomerCharacteristicsDetails: false,
      };

    case GET_MASTER_CUSTOMER_COMPETITOR:
      return {
        ...state,
        masterCustomerCompetitorDetails: action.payload,
      };
    case CREATE_MASTER_CUSTOMER_COMPETITOR:
      return {
        ...state,
        masterCustomerCompetitorDetails: false,
      };
    case UPDATE_MASTER_CUSTOMER_COMPETITOR:
      return {
        ...state,
        masterCustomerCompetitorDetails: false,
      };
    case DELETE_MASTER_CUSTOMER_COMPETITOR:
      return {
        ...state,
        masterCustomerCompetitorDetails: false,
      };
    case GET_MASTER_FEATURE_TYPE:
      return {
        ...state,
        masterFeatureTypeDetails: action.payload,
      };
    case CREATE_MASTER_FEATURE_TYPE:
      return {
        ...state,
        masterFeatureTypeDetails: false,
      };
    case UPDATE_MASTER_FEATURE_TYPE:
      return {
        ...state,
        masterFeatureTypeDetails: false,
      };
    case DELETE_MASTER_FEATURE_TYPE:
      return {
        ...state,
        masterFeatureTypeDetails: false,
      };
    case GET_MASTER_RECURRENCE:
      return {
        ...state,
        masterRecurrence: action.payload,
      };
    case CREATE_MASTER_RECURRENCE:
      return {
        ...state,
        masterRecurrence: false,
      };
    case UPDATE_MASTER_RECURRENCE:
      return {
        ...state,
        masterRecurrence: false,
      };
    case DELETE_MASTER_RECURRENCE:
      return {
        ...state,
        masterRecurrence: false,
      };
    case GET_MASTER_ENGINEERING_EVOLUTION_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionType: action.payload,
      };
    case CREATE_MASTER_ENGINEERING_EVOLUTION_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionType: false,
      };
    case UPDATE_MASTER_ENGINEERING_EVOLUTION_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionType: false,
      };
    case DELETE_MASTER_ENGINEERING_EVOLUTION_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionType: false,
      };
    case GET_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionRiskType: action.payload,
      };
    case CREATE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionRiskType: false,
      };
    case UPDATE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionRiskType: false,
      };
    case DELETE_MASTER_ENGINEERING_EVOLUTION_RISK_TYPE:
      return {
        ...state,
        masterEngineeringEvolutionRiskType: false,
      };
    case GET_MASTER_DEVELOPMENT:
      return {
        ...state,
        masterDevelopment: action.payload,
      };
    case CREATE_MASTER_DEVELOPMENT:
      return {
        ...state,
        masterDevelopment: false,
      };
    case UPDATE_MASTER_DEVELOPMENT:
      return {
        ...state,
        masterDevelopment: false,
      };
    case DELETE_MASTER_DEVELOPMENT:
      return {
        ...state,
        masterDevelopment: false,
      };
    default:
      return state;
  }
};

export default MastersReducer;
