import {GET_PAYMENT_INTENT_FOR_STRIPE_CARD} from 'shared/constants/ActionTypes';

const initialState = {
  paymentIntentForStripeCard: undefined,
};

const PaymentIntentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_INTENT_FOR_STRIPE_CARD:
      return {
        ...state,
        paymentIntentForStripeCard: action.payload,
      };
    default:
      return state;
  }
};
export default PaymentIntentReducer;
