import {
  GET_MAINTENANCE,
  UPDATE_MAINTENANCE,
  CREATE_MAINTENANCE,
} from '../../shared/constants/ActionTypes';

const initialState = {
  maintenanceDetails: false,
};

const MaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAINTENANCE:
      return {
        ...state,
        maintenanceDetails: action.payload,
      };
    case UPDATE_MAINTENANCE:
      return {
        ...state,
        maintenanceDetails: false,
      };
    case CREATE_MAINTENANCE:
      return {
        ...state,
        maintenanceDetails: false,
      };

    default:
      return state;
  }
};

export default MaintenanceReducer;
