import {
  GET_PRODUCT_DETAIL_BY_CASE_ID,
  CREATE_PRODUCT_DATA,
  UPDATE_PRODUCT_DATA,
  GET_BUSINESS_BENEFITS_DATA,
  DELETE_BUSINESS_BENEFITS_DATA,
  CREATE_BUSINESS_BENEFITS_DATA,
  UPDATE_BUSINESS_BENEFITS_DATA,
  GET_PRODUCT_IMAGES,
  CREATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  GET_PRODUCT_IMAGES_ERROR,
} from '../../shared/constants/ActionTypes';

const initialState = {
  productData: false,
  businessBenefitsData: false,
  productDataByCaseId: false,
  isCreateCase: false,
  isUpdateCase: false,
  productImages: false,
};

const CaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_BY_CASE_ID:
      return {
        ...state,
        productDataByCaseId: action.payload,
        businessBenefitsData: false,
        productImages: false,
        isCreateCase: false,
        isUpdateCase: false,
      };
    case CREATE_PRODUCT_DATA:
      return {
        ...state,
        productDataByCaseId: null,
        productData: action.payload,
      };
    case UPDATE_PRODUCT_DATA:
      return {
        ...state,
        productDataByCaseId: null,
        productData: action.payload,
      };

    case CREATE_BUSINESS_BENEFITS_DATA: {
      return {
        ...state,
        businessBenefitsData: false,
      };
    }
    case GET_BUSINESS_BENEFITS_DATA: {
      return {
        ...state,
        businessBenefitsData: action.payload,
      };
    }
    case UPDATE_BUSINESS_BENEFITS_DATA: {
      return {
        ...state,
        businessBenefitsData: false,
      };
    }
    case DELETE_BUSINESS_BENEFITS_DATA: {
      return {
        ...state,
        businessBenefitsData: false,
      };
    }
    case GET_PRODUCT_IMAGES: {
      return {
        ...state,
        productImages: action.payload,
      };
    }
    case GET_PRODUCT_IMAGES_ERROR: {
      return {
        ...state,
        productImages: false,
      };
    }
    case CREATE_PRODUCT_IMAGE: {
      return {
        ...state,
        productImages: null,
      };
    }
    case DELETE_PRODUCT_IMAGE: {
      return {
        ...state,
        productImages: null,
      };
    }
    default:
      return state;
  }
};

export default CaseReducer;
