import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SALES_PROJECTION,
  UPDATE_SALES_PROJECTION,
  CREATE_SALES_PROJECTION,
  SOMETHING_WENT_WRONG,
  SHOW_MESSAGE,
  ADD_SALES_PROJECTION_YEAR,
  DELETE_SALES_PROJECTION_YEAR,
  UPDATE_MAINTENANCE,
  UPDATE_CASH_FLOW,
  UPDATE_CASE_DETAILS,
  UPDATE_CASE_DEFAULT_VALUE,
  // UPDATE_CASE_DETAILS,
  // UPDATE_CASE_DEFAULT_VALUE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getSalesProjectionByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`salesProjections/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_SALES_PROJECTION, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addSalesProjectionByCaseId = (caseId, projectionYears) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `salesProjections/${caseId}/${projectionYears}`,
        {},
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Sales Projection sccessfully create`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: CREATE_SALES_PROJECTION, payload: data.data});
        //  dispatch({type: UPDATE_CASE_DETAILS});
        //  dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateSalesProjectionById = (
  salesProjectionId,
  salesProjectionData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `salesProjections/${salesProjectionId}`,
        salesProjectionData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Sales Projection sccessfully update`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: UPDATE_SALES_PROJECTION, payload: data.data});
        //  dispatch({type: UPDATE_CASE_DETAILS});
        //  dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Sales projection not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addSalesProjectionYearByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`salesProjections/add/${caseId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Sales Projection Year Added`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: ADD_SALES_PROJECTION_YEAR, payload: data.data});
        dispatch({type: UPDATE_CASE_DETAILS});
        dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteSalesProjectionYearByCaseId = (caseId, year) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`salesProjections/${year}/${caseId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your Sales Projection Year Removed`,
        });
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: DELETE_SALES_PROJECTION_YEAR, payload: data.data});
        // dispatch({type: UPDATE_CASE_DETAILS});
        // dispatch({type: UPDATE_CASE_DEFAULT_VALUE});
        dispatch({type: UPDATE_MAINTENANCE});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
