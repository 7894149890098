import {
  GET_ENGINEERING_EXECUTION_ACTIVITIES,
  UPDATE_ENGINEERING_EXECUTION_ACTIVITIES,
  CREATE_ENGINEERING_EXECUTION_ACTIVITIES,
  DELETE_ENGINEERING_EXECUTION_ACTIVITIES,
} from '../../shared/constants/ActionTypes';

const initialState = {
  engineeringExecutionActivitiesDetails: false,
};

const EngineeringExecutionActivitiesReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_ENGINEERING_EXECUTION_ACTIVITIES:
      return {
        ...state,
        engineeringExecutionActivitiesDetails: action.payload,
      };
    case UPDATE_ENGINEERING_EXECUTION_ACTIVITIES:
      return {
        ...state,
        engineeringExecutionActivitiesDetails: false,
      };
    case CREATE_ENGINEERING_EXECUTION_ACTIVITIES:
      return {
        ...state,
        engineeringExecutionActivitiesDetails: false,
      };
    case DELETE_ENGINEERING_EXECUTION_ACTIVITIES:
      return {
        ...state,
        engineeringExecutionActivitiesDetails: false,
      };
    default:
      return state;
  }
};

export default EngineeringExecutionActivitiesReducer;
