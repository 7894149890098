import {
  GET_INVITED_MAIL,
  MAIL_INVITE_EXPIRE,
  INTERNAL_SERVER,
  GET_COMPANY_INVITE,
  INVITE_COMPANY_USER,
  DELETE_INVITED_COMPANY_USER,
} from '../../shared/constants/ActionTypes';

const initialState = {
  invitedLinkData: false,
  isMailInviteExpire: false,
  isInterSeverError: false,
  invitedCompanyUser: false,
};

const InviteLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITED_MAIL:
      return {
        ...state,
        invitedLinkData: action.payload,
      };
    case MAIL_INVITE_EXPIRE:
      return {
        ...state,
        isMailInviteExpire: true,
      };
    case INTERNAL_SERVER:
      return {
        ...state,
        isInterSeverError: true,
      };
    case GET_COMPANY_INVITE:
      return {
        ...state,
        invitedCompanyUser: action.payload,
      };
    case INVITE_COMPANY_USER:
      return {
        ...state,
        invitedCompanyUser: false,
      };
    case DELETE_INVITED_COMPANY_USER:
      return {
        ...state,
        invitedCompanyUser: false,
      };

    default:
      return state;
  }
};

export default InviteLinkReducer;
