import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CREATE_COMPANY_DATA,
  UPDATE_COMPANY_DATA,
  GET_COMPANY_DATA,
  SHOW_MESSAGE,
  GET_COMPANY_USER_FOR_COMPANY,
  CREATE_BUSINESS_UNITS_DATA,
  UPDATE_BUSINESS_UNITS_DATA,
  DELETE_BUSINESS_UNITS_DATA,
  GET_BUSINESS_UNITS_DATA,
  SOMETHING_WENT_WRONG,
  GET_APPROVED_CASE_BY_COMPANY_ID,
  GET_APPROVED_COMPANY_DATA,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getCompanyDataById = (CompanyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`companies/${CompanyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        localStorage.setItem('selectedCurrency', data?.data?.data?.currency);
        dispatch({type: GET_COMPANY_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getApprovedCasesById = (CompanyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`cases/company/1/${CompanyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_APPROVED_CASE_BY_COMPANY_ID, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getApprovedCompanyCasesInfo = (CompanyId, CompanyData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.put(
        `cashFlow/company/${CompanyId}`,
        CompanyData,
      );
      if (data.status === 200) {
        const successMessage = 'Company info successfully fetched';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_APPROVED_COMPANY_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createCompanyData = (CompanyData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post('companies', CompanyData);
      if (data.status === 200) {
        const successMessage = 'Your Company successfully created';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        localStorage.setItem('selectedCurrency', CompanyData.currency);
        dispatch({type: CREATE_COMPANY_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'User not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCompanyDataById = (CompanyData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `companies/${CompanyData.id}`,
        CompanyData,
      );
      if (data.status === 200) {
        const successMessage = 'Your Company successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        localStorage.setItem('selectedCurrency', data?.data?.data?.currency);
        dispatch({type: UPDATE_COMPANY_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getCompanyUserByCompanyId = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`users/company/${companyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_USER_FOR_COMPANY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//Business Units Module

export const getBusinessUnitsByCompanyId = (CompanyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`businessUnits/${CompanyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_BUSINESS_UNITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addBusinessUnitsByCompanyId = (companyId, businessUnitData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `businessUnits/${companyId}`,
        businessUnitData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your business units successfully create`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_BUSINESS_UNITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company not found';
          break;
        case 400:
          errorMessage = error?.response?.data?.errorMessage;
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateBusinessUnitById = (
  companyId,
  businessUnitId,
  businessUnitData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `businessUnits/${companyId}/${businessUnitId}`,
        businessUnitData,
      );
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your business unit successfully update`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_BUSINESS_UNITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'business unit not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteBusinessUnitById = (businessUnitId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`businessUnits/${businessUnitId}`);
      if (data.status === 200) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: `Your business unit successfully delete`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_BUSINESS_UNITS_DATA, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
