import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_INITIATIVE_STRATEGIES,
  UPDATE_INITIATIVE_STRATEGIES,
  CREATE_INITIATIVE_STRATEGIES,
  DELETE_INITIATIVE_STRATEGIES,
  SHOW_MESSAGE,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getInitiativeStrategiesByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`initiativeStrategy/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_INITIATIVE_STRATEGIES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addInitiativeStrategies = (caseId, initiativeStrategiesData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `initiativeStrategy/${caseId}`,
        initiativeStrategiesData,
      );
      if (data.status === 200) {
        const successMessage = `Your ${initiativeStrategiesData.strategy_type} Strategy successfully add`;
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_INITIATIVE_STRATEGIES, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateInitiativeStrategiesById = (
  initiativeStrategiesId,
  initiativeStrategiesData,
) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `initiativeStrategy/${initiativeStrategiesId}`,
        initiativeStrategiesData,
      );
      if (data.status === 200) {
        const successMessage = `Your ${initiativeStrategiesData.strategy_type} Strategy successfully update`;
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_INITIATIVE_STRATEGIES, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Initiative strategies not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteInitiativeStrategiesById = (initiativeStrategiesId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `initiativeStrategy/${initiativeStrategiesId}`,
      );
      if (data.status === 200) {
        const successMessage = `Your Strategy successfully delete`;
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});

        dispatch({type: DELETE_INITIATIVE_STRATEGIES, payload: data.data});
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
