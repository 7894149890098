import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
const Cases = React.lazy(() => import('./case'));
const BusinessCaseDetails = React.lazy(() => import('./businessCaseDetail'));
const StackHolder = React.lazy(() => import('./stackHolder'));
const Report = React.lazy(() => import('../caseReport/report'));

export const casePagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/cases',
    element: <Cases />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/cases/BusinessCaseDetails/:businessCaseId',
    element: <BusinessCaseDetails />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/cases/BusinessCaseDetails/:businessCaseId/stackHolder',
    element: <StackHolder />,
  },
  {
    permittedRole: [RoutePermittedRole.caseViewer, RoutePermittedRole.admin],
    path: '/home/cases/BusinessCaseDetails/:businessCaseId/CaseReport',
    element: <Report />,
  },
];
