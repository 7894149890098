import {GET_CASE_REPORT_DATA} from '../../shared/constants/ActionTypes';

const initialState = {
  reportCaseData: false,
};

const CaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASE_REPORT_DATA:
      return {
        ...state,
        reportCaseData: action.payload,
      };
    default:
      return state;
  }
};

export default CaseReducer;
