import {
  GET_INITIATIVE_STRATEGIES,
  UPDATE_INITIATIVE_STRATEGIES,
  CREATE_INITIATIVE_STRATEGIES,
  DELETE_INITIATIVE_STRATEGIES,
} from '../../shared/constants/ActionTypes';

const initialState = {
  initiativeStrategiesDetails: false,
};

const InitiativeStrategiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INITIATIVE_STRATEGIES:
      return {
        ...state,
        initiativeStrategiesDetails: action.payload,
      };
    case UPDATE_INITIATIVE_STRATEGIES:
      return {
        ...state,
        initiativeStrategiesDetails: false,
      };
    case CREATE_INITIATIVE_STRATEGIES:
      return {
        ...state,
        initiativeStrategiesDetails: false,
      };
    case DELETE_INITIATIVE_STRATEGIES:
      return {
        ...state,
        initiativeStrategiesDetails: false,
      };
    default:
      return state;
  }
};

export default InitiativeStrategiesReducer;
