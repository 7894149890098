import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SOMETHING_WENT_WRONG,
  GET_MAN_POWER,
  UPDATE_MAN_POWER,
  CREATE_MAN_POWER,
  DELETE_MAN_POWER,
  SHOW_MESSAGE,
  UPDATE_FINANCIAL_EVALUATION,
  UPDATE_CASH_FLOW,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getManPowerByTypeId = (type, typeId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`manpower/${type}/${typeId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: GET_MAN_POWER,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addManPower = (caseId, type, typeId, manpowerData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(
        `manpower/${caseId}/${type}/${typeId}`,
        manpowerData,
      );
      if (data.status === 200) {
        dispatch({type: SHOW_MESSAGE, payload: 'Manpower sccessfully created'});
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: CREATE_MAN_POWER,
          payload: data.data,
        });
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateManPowerById = (caseId, manpowerId, manpowerData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `manpower/${caseId}/${manpowerId}`,
        manpowerData,
      );
      if (data.status === 200) {
        dispatch({type: SHOW_MESSAGE, payload: 'Manpower sccessfully update'});
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: UPDATE_MAN_POWER,
          payload: data.data,
        });
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Man power not found.';
          break;
        default:
          errorMessage = SOMETHING_WENT_WRONG;
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteManPowerById = (caseId, manpowerId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`manpower/${caseId}/${manpowerId}`);
      if (data.status === 200) {
        dispatch({type: SHOW_MESSAGE, payload: 'Manpower sccessfully delete'});
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: DELETE_MAN_POWER,
          payload: data.data,
        });
        dispatch({type: UPDATE_FINANCIAL_EVALUATION});
        dispatch({type: UPDATE_CASH_FLOW});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = SOMETHING_WENT_WRONG;
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
