import React, {useEffect} from 'react';
import {Layout} from 'antd';
import {AppSuspense} from '../../index';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../../../pages';
import AppErrorBoundary from '../AppErrorBoundary';
import './index.style.less';
import generateRoutes from '../../utility/RouteGenerator';
import {useAuthUser} from '../../utility/AuthHooks';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {navigationInitialUrl} from 'shared/constants/utils';
import {useDispatch, useSelector} from 'react-redux';
import {getUserBillingInfo} from 'redux/actions';
//import { replace } from 'lodash';
//import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

const {Content} = Layout;

//onst whiteListUrls = ['/home/packages/card-stripe/*'];

const AppContentView = () => {
  // const location = useLocation();
  const {user, isAuthenticated} = useAuthUser();
  const data = navigationInitialUrl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {userSubscriptionIsBilling} = useSelector(({common}) => common);
  useEffect(() => {
    if (user) {
      dispatch(getUserBillingInfo(user?.id));
    }
  }, [navigate]);

  useEffect(() => {
    const {is_billing_started, cancel_at_period_end, subscription_status} =
      userSubscriptionIsBilling?.data || {};

    const currentUrl = location.pathname;
    if (is_billing_started === true && subscription_status === 'active') {
      if (currentUrl.includes('/home/packages/card-stripe')) {
        navigate('/home/packages/card-stripe/billinginfo', {replace: true});
      }
    }

    if (
      !is_billing_started &&
      subscription_status === 'active' &&
      cancel_at_period_end
    ) {
      if (currentUrl === '/home/packages') {
        navigate('/home/packages/card-stripe/billinginfo', {replace: true});
      }
    } else {
      if (currentUrl === '/home/packages' && subscription_status === 'active') {
        navigate('/home/packages/card-stripe/billinginfo', {replace: true});
      }
    }
  }, [userSubscriptionIsBilling, user?.id, navigate]);

  return (
    <Content
      className={isAuthenticated ? 'main-content-view' : ' main-container-app'}>
      <AppSuspense>
        <AppErrorBoundary>
          {generateRoutes({
            isAuthenticated: isAuthenticated,
            userRole: user?.role,
            unAuthorizedStructure,
            authorizedStructure,
            anonymousStructure,
          })}
          <Routes>
            <Route path='/' element={<Navigate to={data} replace />} />
          </Routes>
        </AppErrorBoundary>
      </AppSuspense>
    </Content>
  );
};

export default AppContentView;
