import {
  GET_SALES_PROJECTION,
  UPDATE_SALES_PROJECTION,
  CREATE_SALES_PROJECTION,
  ADD_SALES_PROJECTION_YEAR,
  DELETE_SALES_PROJECTION_YEAR,
} from '../../shared/constants/ActionTypes';

const initialState = {
  salesProjectionDetails: false,
};

const SalesProjectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_PROJECTION:
      return {
        ...state,
        salesProjectionDetails: action.payload,
      };
    case UPDATE_SALES_PROJECTION:
      return {
        ...state,
        salesProjectionDetails: false,
      };
    case CREATE_SALES_PROJECTION:
      return {
        ...state,
        salesProjectionDetails: false,
      };
    case ADD_SALES_PROJECTION_YEAR:
      return {
        ...state,
        salesProjectionDetails: false,
      };
    case DELETE_SALES_PROJECTION_YEAR:
      return {
        ...state,
        salesProjectionDetails: false,
      };
    default:
      return state;
  }
};

export default SalesProjectionReducer;
